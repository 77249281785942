import algoliasearch from 'algoliasearch/lite';

const APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
const SEARCH_API_KEY = process.env.NEXT_PUBLIC_SEARCH_API_KEY;

const searchClient = algoliasearch(APP_ID, SEARCH_API_KEY);

const DATA_SOURCE =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'prod'
    : process.env.NEXT_PUBLIC_VERCEL_ENV === 'test'
    ? 'dev'
    : 'stg';

const getAlgoliaIndex = name => {
  const indexName = `${DATA_SOURCE}_${name}`;
  return indexName;
};

const getSortValue = (index, field, order) => {
  if (order !== 'asc' && order !== 'desc') return index;

  return `${index}_${field}_${order}`;
};

export { searchClient, getAlgoliaIndex, getSortValue };
