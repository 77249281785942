const { getAlgoliaIndex } = require('../utils/aloglia');

export const PUBLICATION_INDEX_NAME = getAlgoliaIndex('publications');
export const PUBLICATION_LINK = '/publications';

export const SORT_FIELDS = {
  PopularityRank: 'popularity_rank',
  ShortestTurnAroundTime: 'shortest_turnaround_time',
  PublishedAt: 'publishedAt',
  Price: 'price',
  DomainRanking: 'domain_ranking',
  DomainAuthority: 'domain_authority',
  Name: 'name',
};
